import { render, staticRenderFns } from "./QueueItemOnService.vue?vue&type=template&id=0b78e021&scoped=true&"
import script from "./QueueItemOnService.vue?vue&type=script&lang=js&"
export * from "./QueueItemOnService.vue?vue&type=script&lang=js&"
import style0 from "./QueueItemOnService.vue?vue&type=style&index=0&id=0b78e021&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b78e021",
  null
  
)

export default component.exports