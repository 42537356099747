<template>
  <div>
    <div class="panel-block " style="justify-content: space-between;">
      <span class="panel-icon">
        <i
          class=""
          aria-hidden="true"
          :class="{
            'has-text-info fas fa-user': data.isPriority,
            'far fa-user': !data.isPriority
          }"
        ></i>
      </span>
      <span> {{ index + 1 }}. {{ data.name }} </span>

      <div class="field is-grouped">
        <p class="control">
          <b-tooltip label="Iniciar atendimmento" class="is-info">
            <button
              v-if="selectedQueue === 'costumers'"
              class="button  is-info is-outlined"
              @click="
                confirmOnservice(
                  'costumersOnService',
                  data.locationId,
                  data.phoneNumber
                )
              "
            >
              <span class="icon is-small">
                <i class="fas fa-play-circle"></i>
              </span>
            </button>
          </b-tooltip>
          <b-tooltip label="Finalizar atendimento" class="is-info">
            <button
              v-if="selectedQueue !== 'costumers'"
              class="button  is-info is-outlined"
              @click="
                confirmOnServed(
                  'costumersServed',
                  data.locationId,
                  data.phoneNumber
                )
              "
            >
              <span class="icon is-small">
                <i class="far fa-check-circle"></i>
              </span>
            </button>
          </b-tooltip>
        </p>
        <p class="control">
          <b-tooltip label="Notificar via whatsapp" class="is-success">
            <button
              v-if="selectedQueue === 'costumers'"
              class="button  is-primary "
              :class="{ 'is-outlined': !data.isNotified }"
              @click="
                confirmNotify(
                  `👩‍💻 Olá, tudo bem? Boas notícias, o seu atendimento está próximo. 
Aproxime-se para ser atendido`,
                  data.phoneNumber,
                  data.locationId
                )
              "
            >
              <span class="icon is-small">
                <!-- <i v-if="isNotified === true" class="fas fa-check-double"></i> -->
                <i
                  class=""
                  :class="{
                    'fab fa-whatsapp': !data.isNotified,
                    'fas fa-check-double': data.isNotified
                  }"
                ></i>
              </span>
            </button>
          </b-tooltip>
        </p>
        <p class="control">
          <b-tooltip label="Excluir da fila" class="is-danger">
            <button
              v-if="selectedQueue === 'costumers'"
              class="button  is-danger"
              @click="
                confirmDelete('exited', data.locationId, data.phoneNumber)
              "
            >
              <span class="icon is-small">
                <i class="fas fa-user-times"></i>
              </span>
            </button>
          </b-tooltip>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import firebase from "firebase/app";

export default {
  props: {
    data: Object,
    index: Number,
    isProductDeletionPending: Boolean,
    disableActions: Boolean,
    selectedQueue: String,
    newClientIdAfterFinished: String
  },
  methods: {
    ...mapMutations("costumers", [
      "setLocationId",
      "setCostumerName",
      "setCostumerIsPriority",
      "setCostumerPhone",
      "setTabState",
      "setChecked",
      "setCostumerIsInQueue",
      "isNotified"
    ]),
    ...mapActions("costumers", ["updateCostumerOnQueue"]),
    ...mapActions("costumers", ["notifyCostumer"]),
    confirmDelete(action, location, phone) {
      this.$buefy.dialog.confirm({
        message: "Deseja remover o cliente da fila?",
        type: "is-danger",
        onConfirm: () => {
          this.$store
            .dispatch("costumers/updateCostumerOnQueue", [
              action,
              location,
              phone
            ])
            .then(() => {
              this.$buefy.toast.open("Cliente removido da fila");
            });
        }
      });
    },
    confirmOnServed(action, location, phone) {
      this.$buefy.dialog.confirm({
        message: "Deseja finalizar o atendimento?",
        type: "is-info",
        onConfirm: () => {
          this.$store
            .dispatch("costumers/updateCostumerOnQueue", [
              action,
              location,
              phone
            ])
            .then(() => {
              const msg = `👩‍💻 Olá, recebi a informação de  que seu atendimento foi conlcuído. 

Espero que tudo tenha ocorrido bem. 

Que tal deixar sua avalição sobre o atendimento? https://maxcap-b8e54.firebaseapp.com/costumers/checkin/${location}/${
                this.newClientIdAfterFinished
              }`;
              this.$store.dispatch("costumers/notifyCostumer", [msg, phone]);
              this.$store.commit(
                "queue/setSelectedQueue",
                "costumersOnService"
              );
              this.$store.commit("");
              this.$buefy.toast.open("Atendimento finalizado");
            });
        }
      });
    },
    confirmOnservice(action, location, phone) {
      this.$buefy.dialog.confirm({
        message: "Deseja iniciar o atendimento?",
        type: "is-info",
        onConfirm: () => {
          this.$store
            .dispatch("costumers/updateCostumerOnQueue", [
              action,
              location,
              phone
            ])
            .then(() => {
              this.$store.commit("queue/setSelectedQueue", "costumers");
              this.$buefy.toast.open("Atendimento inciado");
            });
        }
      });
    },
    confirmNotify(msg, phone, location) {
      this.$buefy.dialog.confirm({
        title: "Deseja notificar o cliente?",
        size: "is-small",
        message:
          "Mensagem: \n Olá, tudo bem? Pode se dirigir ao atendimento. Você é o próximo da fila.",
        confirmText: "Enviar",
        cancelText: "Cancelar",
        trapFocus: true,
        type: "is-primary",
        onConfirm: () => {
          this.$store
            .dispatch("costumers/notifyCostumer", [msg, phone])
            .then(() => {
              this.$buefy.toast.open({
                message: "Notificação enviada",
                type: "is-success"
              });
              this.commitNotify(phone, location);
            })
            .catch(() => {
              this.$buefy.toast.open({
                message:
                  "Falha ao enviar mensagem para o cliente, número inválido",
                type: "is-danger"
              });
            });
        }
      });
    },
    commitNotify(phone, location) {
      firebase
        .firestore()
        .collection("locations")
        .doc(location)
        .collection("costumers")
        .doc(phone)
        .update({ isNotified: true })
        .then(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .product-link {
    color: $vue-color;
  }

  .delete-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    color: $danger-color;
    border-color: $danger-color;
  }
}
</style>
