export const queueManagementSteps = [
  {
    target: '.v-step-9', // We're using document.querySelector() under the hood
    header: {
      title: 'Gestão da fila'
    },
    content:
      'Esta é a tela de gerenciamento de fila. Aqui você fara toda a gestão dos seus clientes.'
  },
  {
    target: '.v-step-10',
    header: {
      title: 'Copiar link'
    },
    content:
      'Uma das formas para os seus clientes acessaram a fila é compartilhando seu link. Clique em "Copiar Link" e em seguida compartilhe via mensagem ou como desejar.',
    params: {
      placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  },
  {
    target: '.v-step-11',
    header: {
      title: 'Status da fila'
    },
    content:
      'Alguns números sobre a sua fila no momento, tudo atualizado em tempo real.',
    params: {
      placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  },
  {
    target: '.v-step-12',
    header: {
      title: 'Adicione clientes'
    },
    content:
      'Além do QRCode e de enviar o link, você pode adicionar clientes manualmente. Por favor, tenha certeza que o telefone está correto, dessa forma poderemos notificá-los',
    params: {
      placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  },
  {
    target: '.v-step-13',
    content:
      'Alterne entre os clientes que estão aguardando na fila e os clientes que estão em atendimento.',
    params: {
      placement: 'top',
      highlight: true // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  },
  {
    target: '.v-step-14',
    header: {
      title: 'Lista de clientes'
    },
    content:
      'É possivel: <br> <ul><li><strong class="has-text-white">1. Iniciar um atendimento</strong></li><li><strong class="has-text-white">2. Enviar uma notificação via whatsapp que o atendimento está próximo.</strong></li><li><strong class="has-text-white">3. Excluir o cliente da fila</strong></li><li><strong class="has-text-white">4. Finalizar o atendimento</strong></li></ol>',
    params: {
      placement: 'top',
      highlight: true // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  }
]

export default queueManagementSteps
