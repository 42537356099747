var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"panel-block ",staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"panel-icon"},[_c('i',{class:{
            'has-text-info fas fa-user': _vm.data.isPriority,
            'far fa-user': !_vm.data.isPriority
          },attrs:{"aria-hidden":"true"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.index + 1)+". "+_vm._s(_vm.data.name)+" ")]),_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control"},[_c('b-tooltip',{staticClass:"is-info",attrs:{"label":"Iniciar atendimmento"}},[(_vm.selectedQueue === 'costumers')?_c('button',{staticClass:"button  is-info is-outlined",on:{"click":function($event){return _vm.confirmOnservice(
                  'costumersOnService',
                  _vm.data.locationId,
                  _vm.data.phoneNumber
                )}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-play-circle"})])]):_vm._e()]),_c('b-tooltip',{staticClass:"is-info",attrs:{"label":"Finalizar atendimento"}},[(_vm.selectedQueue !== 'costumers')?_c('button',{staticClass:"button  is-info is-outlined",on:{"click":function($event){return _vm.confirmOnServed(
                  'costumersServed',
                  _vm.data.locationId,
                  _vm.data.phoneNumber
                )}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"far fa-check-circle"})])]):_vm._e()])],1),_c('p',{staticClass:"control"},[(_vm.selectedQueue === 'costumers')?_c('button',{staticClass:"button  is-primary ",class:{ 'is-outlined': !_vm.data.isNotified },on:{"click":function($event){return _vm.confirmNotify(
                "👩‍💻 Olá, tudo bem? Boas notícias, o seu atendimento está próximo. \nAproxime-se para ser atendido",
                _vm.data.phoneNumber,
                _vm.data.locationId
              )}}},[_c('span',{staticClass:"icon is-small"},[_c('i',{class:{
                  'fab fa-whatsapp': !_vm.data.isNotified,
                  'fas fa-check-double': _vm.data.isNotified
                }})])]):_vm._e()]),_c('p',{staticClass:"control"},[(_vm.selectedQueue === 'costumers')?_c('button',{staticClass:"button  is-danger",on:{"click":function($event){return _vm.confirmDelete('exited', _vm.data.locationId, _vm.data.phoneNumber)}}},[_vm._m(0)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-user-times"})])}]

export { render, staticRenderFns }