<template>
  <transition @enter="transitionEnter" @leave="transitionLeave">
    <div class="columns">
      <div class="column is-full">
        <nav class="panel">
          <p class="panel-heading">
            Fila
            <costumer-add
              :service-status="null"
              :reset-after="true"
            ></costumer-add>
          </p>
          <div class="panel-block">
            <p class="control has-icons-left">
              <input class="input" type="text" placeholder="Search" />
              <span class="icon is-left">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
          </div>
          <p class="panel-tabs ">
            <a
              :class="{
                'is-active': selectedQueue === 'costumers'
              }"
              @click="alternateTabs('costumers')"
              ><div class="tags has-addons">
                <span class="tag">Em fila</span>
                <span class="tag is-primary">{{ qtyCostumers }}</span>
              </div>
            </a>
            <a
              :class="{
                'is-active': selectedQueue === 'costumersOnService'
              }"
              @click="alternateTabs('costumersOnService')"
              ><div class="tags has-addons">
                <span class="tag">Em atendimento </span>
                <span class="tag is-info">{{ qtyCostumersOnService }}</span>
              </div></a
            >
          </p>
          <transition-group name="list" tag="div">
            <queue-item
              v-for="(q, index) in queue"
              v-show="selectedQueue === 'costumers'"
              :key="q.name"
              v-model="queue"
              class="has-centered-text v-step-14 list-item"
              :index="index"
              :data="q"
              :selected-queue="selectedQueue"
              :new-client-id-after-finished="newClientIdAfterFinished"
            ></queue-item>
          </transition-group>
          <transition-group name="list" tag="div">
            <queue-item-on-service
              v-for="(q, index) in queueOnService"
              v-show="selectedQueue === 'costumersOnService'"
              :key="q.name + 'service'"
              v-model="queueOnService"
              class="has-centered-text v-step-14 list-item"
              :index="index"
              :data="q"
              :selected-queue="selectedQueue"
              :new-client-id-after-finished="newClientIdAfterFinished"
            ></queue-item-on-service>
          </transition-group>

          <div
            v-show="qtyCostumers === 0 && selectedQueue === 'costumers'"
            style="padding: 20px"
          >
            <a class="panel-block">
              <span class="panel-icon">
                <i class="far fa-user" aria-hidden="true"></i>
              </span>
              <span class="has-text-centered">
                A fila está vazia no nomento
              </span>
            </a>
          </div>

          <div
            v-show="
              qtyCostumersOnService === 0 && selectedQueue !== 'costumers'
            "
            style="padding: 20px"
          >
            <a class="panel-block">
              <span class="panel-icon">
                <i class="far fa-user" aria-hidden="true"></i>
              </span>
              <h1 class="has-text-centered">
                Ninguem em atendimento no momento
              </h1>
            </a>
          </div>
        </nav>
      </div>
    </div>
  </transition>
</template>

<script>
import QueueItem from '@/components/queue/QueueItem'
import QueueItemOnService from '@/components/queue/QueueItemOnService'
import CostumerAdd from '@/components/costumer/CostumerAdd'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from 'firebase/app'
import router from '../../router'

export default {
  components: {
    QueueItem,
    QueueItemOnService,
    CostumerAdd
  },
  props: {
    data: Object,
    index: Number,
    isProductDeletionPending: Boolean,
    disableActions: Boolean,
    newClientIdAfterFinished: String
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('costumers', ['qtyCostumers', 'qtyCostumersOnService']),
    ...mapState('queue', [
      'locationId',
      'selectedQueue',
      'queue',
      'queueOnService'
    ]),
    ...mapGetters('queue', ['getQueue']),
    ...mapGetters('costumers', ['getQtyWaiting']),
    ...mapMutations('queue', [
      'setQueue',
      'setQueueOnService',
      'setSelectedQueue'
    ])
  },
  created() {
    this.$store.commit('queue/setLocationId', router.currentRoute.params.id)
    this.initialValues(this.locationId, 'costumers')
    this.initialValues(this.locationId, 'costumersOnService')
  },
  methods: {
    ...mapActions('queue', ['initializeQueue']),
    async initialValues(locationId, status) {
      firebase
        .firestore()
        .collection('locations')
        .doc(locationId)
        .collection(status)
        .orderBy('createTimestamp')
        .onSnapshot(snapshot => {
          const response = snapshot.docs.map(doc => doc.data())
          const { size } = snapshot
          if (status === 'costumers') {
            this.$store.commit('queue/setQueue', response)
            this.$store.commit('costumers/setQtyCostumers', size)
            return
          }
          this.$store.commit('queue/setQueueOnService', response)
          this.$store.commit('costumers/setQtyCostumersOnService', size)
        })
    },
    alternateTabs(status) {
      this.$store.commit('queue/setSelectedQueue', status)
    },
    transitionEnter(el, done) {
      console.log('transitionEnter')
      done()
    },
    transitionLeave(el, done) {
      console.log('transitionLeave')
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
