<template>
  <div
    v-if="products"
    class="columns is-centered is-2 is-multiline has-text-centered v-step-9"
  >
    <div class=" column is-5">
      <h1 class="title has-text-centered">{{ products.name }}</h1>
      <h5 class="subtitle has-text-centered">
        Para que seus clientes acessem a fila, disponibilize este QRCode de
        forma que possam acessá-lo com câmera do celular (Ex: impresso ou em uma
        tela) ou copie o link da fila para compartilhamento on-line
      </h5>
      <figure class="image container is-128x128">
        <img alt="qr-code is-block" class="logo" :src="products.qrCodeUrl" />
      </figure>
      <div class="columns">
        <div class="column is-centered v-step-10">
          <button
            class="button is-primary"
            style="margin-top: 15px"
            @click="doCopy()"
          >
            <span class="icon is-small">
              <i class="fas fa-link"></i>
            </span>
            <span>
              Copiar Link
            </span>
          </button>
        </div>
      </div>
      <div class="columns  is-centered v-step-11">
        <nav class="level ">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Capacidade</p>
              <p class="title">{{ products.maxQtd }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Na fila</p>
              <p class="title">{{ qtyCostumers }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered ">
            <div>
              <p class="heading">Em atendimento</p>
              <p class="title">{{ qtyCostumersOnService }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Status da fila</p>
              <p class="title"><span class="tag is-primary">Aberta</span></p>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="column is-5  v-step-13">
      <div class="columns ">
        <div class="column is-full">
          <queue-list
            style="width: 100%"
            :qty-costumers="qtyCostumers"
            :qty-costumers-on-service="qtyCostumersOnService"
            :new-client-id-after-finished="newClientIdAfterFinished"
          ></queue-list>
        </div>
      </div>
    </div>
    <v-tour
      name="queueManagementTour"
      :steps="steps"
      :callbacks="myCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import router from '../router'
import QueueList from '@/components/queue/QueueList'

import QueueManagementSteps from '@/misc/tours/queue-management-steps'

export default {
  components: { QueueList },
  data() {
    return {
      steps: QueueManagementSteps,
      myCallbacks: {
        onStop() {
          localStorage.setItem('queuTourCompleted', true)
        }
      }
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('open', ['products', 'locationId']),
    ...mapState('costumers', [
      'qtyCostumers',
      'qtyCostumersOnService',
      'newClientIdAfterFinished'
    ]),
    ...mapActions('open', ['getLocationQrCode'])
  },

  watch: {
    qtyCostumers: {
      handler() {},
      immediate: true
    },
    qtyCostumersOnService: {
      handler() {},
      immediate: true
    }
  },
  mounted() {
    if (localStorage.getItem('queuTourCompleted') === null) {
      this.$tours.queueManagementTour.start()
    }
  },
  created() {
    this.setLocationId = router.currentRoute.params.id
    this.$store.commit('open/setLocationId', router.currentRoute.params.id)
    this.$store.commit(
      'costumers/setLocationName',
      router.currentRoute.params.name
    )
    this.actualQtd()
    this.$store.dispatch('open/getLocationQrCode').then(
      response => {
        this.$store.commit('open/setProducts', response)
      },
      error => {
        console.log(error)
      }
    )
  },
  methods: {
    ...mapMutations('costumers', [
      'setQtyCostumers',
      'setQtyCostumersOnService'
    ]),
    actualQtd() {
      firebase
        .firestore()
        .collection('locations')
        .doc(router.currentRoute.params.id)
        .collection('costumers')
        .get()
        .then(snap => {
          this.$store.commit('costumers/setQtyCostumers', snap.size)

          firebase
            .firestore()
            .collection('locations')
            .doc(router.currentRoute.params.id)
            .collection('costumersOnService')
            .get()
            .then(snapOnService => {
              this.$store.commit(
                'costumers/setQtyCostumersOnService',
                snapOnService.size
              )
            })
        })
    },
    doCopy() {
      this.$copyText(
        `${window.location.origin}/costumers/checkin/${this.products.id}/null/${
          this.products.name
        }`
      ).then(() =>
        this.$buefy.toast.open('Link copiado, comaprtilhe com seus clientes!')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
